import errorHandler from "./handler/erroHandler";
import axiosInstance from "./manager/manager.service";
import { baseUrl } from "../constants/config";
import { customFunction } from "../utils/customFunction";

export const morceauxService = {
    get,
    getById,
    create,
    update,
    active,
    desactive

};


async function getById(id) {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/morceaux/${id}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function get() {
    let artiste = customFunction.destoreParam('yendiuartiste');

    try {
        let rets = artiste != null ? await axiosInstance.get(`${baseUrl}/artistes/${artiste.id}/morceaux`) : await axiosInstance.get(`${baseUrl}/morceaux`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function create(mo) {
    try {
        let ret = await axiosInstance.post(`${baseUrl}/morceaux`, mo);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function update(mo, id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/morceaux/${id}`, mo);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}



async function active(id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/morceaux/${id}/activate`);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function desactive(id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/morceaux/${id}/desactivate`);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}